import { LOGIN } from "@root/routes";
const origins = {
    appDomain: process.env.API_URL ?
        process.env.API_URL : `https://maxapp.co.in`,
    appOrigin: process.env.APP_URL ?
        process.env.APP_URL : `https://www.maxathome.in`,
    paymentGateway: process.env.PAYMENT_GATEWAY ?
        process.env.PAYMENT_GATEWAY : `https://digicareportal.maxhealthcare.in/DigicarePayment/Default.aspx/?PaymentId=`,
};

const provider = {
    triggerOtpApi: `${origins.appDomain}/miscs/triggerOTP`,
    verifyOtpAndFetchPatientApi: `${origins.appDomain}/miscs/verifyOTP`,
    fetchDeliveryAddressApi: `${origins.appDomain}/miscs/fetch-delivery-address`,
    updateDeliveryAddressApi: `${origins.appDomain}/miscs/update-delivery-address`,
    fetchSlotsApi: `${origins.appDomain}/miscs/fetch-slots`,
    fetchPatientBookingsApi: `${origins.appDomain}/miscs/fetch-history`,
    fetchPatientsApi: `${origins.appDomain}/miscs/fetch-patients`,
    patientRegistrationApi: `${origins.appDomain}/miscs/register-patient`,
    fetchPatientReportsApi: `${origins.appDomain}/miscs/fetch-reports`,
    reserveSlotApi: `${origins.appDomain}/miscs/reserve-slot`,
    completePaymentForHomeApi: `${origins.appDomain}/miscs/complete-payment-for-home`,
    startPaymentOnlineApi: `${origins.appDomain}/miscs/start-payment-online`,
    completePaymentOnlineApi: `${origins.appDomain}/miscs/complete-payment-online`,
    fetchEntityApi: `${origins.appDomain}/miscs/fetch-entity`,
    fetchSalutationApi: `${origins.appDomain}/miscs/fetch-salutations`,
    fetchPromocodesApi: `${origins.appOrigin}/api/fetch-promocodes`,
    fetchServicesApi: `${origins.appDomain}/miscs/fetch-services`,
    getCSRFToken: `${origins.appOrigin}/sanctum/csrf-cookie`,
    userLogin: `${origins.appOrigin}/api/auth/register`,
    userUpdate: `${origins.appOrigin}/api/auth/user/update`,
    getAuthUser: `${origins.appOrigin}/api/auth/user`,
    logoutUser: `${origins.appOrigin}/api/auth/logout`,
    getCartItems: `${origins.appOrigin}/api/cart`,
    retrieveStore: `${origins.appOrigin}/api/cart/retrieve-store`,
    retryBookingApi: `${origins.appOrigin}/api/cart/retry-booking`,
    resetCartAndStoreApi: `${origins.appOrigin}/api/cart/reset-booking`,
    updateStore: `${origins.appOrigin}/api/cart/store`,
    removeCartItem: `${origins.appOrigin}/api/cart/remove-item`,
    makePaymentApi: `${origins.appDomain}api/v1/vaccination/makePayment`,
    vaccinationSitesFetchApi: `${origins.appDomain}vaccination-sites`,
    vaccinationCitiesFetchApi: `${origins.appDomain}vaccination-cities`,
    addPatientApi: `${origins.appDomain}api/v1/vaccination/registerPatient`,
    setSpokeApi: `${origins.appOrigin}/api/set-spoke`,
    verifyOTPAndSecret: `${origins.appOrigin}/api/otp/verify`,
    getAvailableSlotTimingsApi: `${origins.appDomain}api/v1/vaccination/fetchSlotTimings`,
    getAvailableSlotCapacityApi: `${origins.appDomain}api/v1/vaccination/fetchSlotCapacity`,
    initialAppPage: LOGIN,
    totalVaccinationSlotCapacity: 30,
    timeSlotOrder: ["morning", "afternoon", "evening"],
    getItemProfileLink: (test) =>
        //`${origins.appOrigin}/services/${test.ServiceId}/tests/${test.ItemSeqID}`,
        `${origins.appOrigin}/services/pathology/tests/${test.ItemSeqID}`,
    getPackageProfileLink: (wellnessPackage) =>
        `${origins.appOrigin}/packages/${wellnessPackage.Slug}`,
    availableOrderTypes: [
        "UnScheduled",
        "Scheduled",
        "ReScheduled",
        "Cancelled",
        "Completed",
    ],
};
export { origins, provider };
